import React from "react"
import DynamicComponent from "./DynamicComponent"
import { sbEditable } from "@storyblok/storyblok-editable"
import { Seo } from "./Seo"

const Page = ({ blok }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ))

  const hasTitle =
    blok.title && blok.title.length ? (
      <h1 className="text-4xl font-bold bg-primary tracking-wide text-center py-5">
        {blok.title}
      </h1>
    ) : null
  return (
    <div {...sbEditable(blok)}>
      <Seo
        meta={{
          title: blok.title,
          ...blok.meta,
        }}
      />
      {hasTitle}
      {content}
    </div>
  )
}

export default Page
