import React from "react"
import DynamicComponent from "./DynamicComponent"
import { sbEditable } from "@storyblok/storyblok-editable"

const Container = ({ blok }) => {
  const { image, height, bgColor, bgFilterOpacity = 0, id } = blok

  return (
    <div
      {...sbEditable(blok)}
      id={id}
      style={{
        backgroundColor: bgColor,
        height,
        backgroundImage: image ? `url(${image.filename})` : undefined,
      }}
      className="relative w-full bg-cover bg-center"
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          backgroundColor: `rgba(0,0,0,${bgFilterOpacity})`,
        }}
      />
      <div className="relative container w-full flex flex-wrap p-4 py-10 mx-auto z-10">
        {blok.body.map(blok => (
          <DynamicComponent key={blok._uid} blok={blok} />
        ))}
      </div>
    </div>
  )
}

export default Container
