import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

const defaultTitle = "Linguazinga - Language school"
export const Seo = ({ meta }) => {
  const { pathname } = useLocation()
  const {
    title = defaultTitle,
    description,
    og_description: ogDescription,
    og_image: ogImage,
    og_title: ogTitle,
    twitter_description: twitterDescription,
    twitter_image: twitterImage,
    twitter_title: twitterTitle,
  } = meta

  const fullURL = url => `${process.env.VERCEL_URL}${url}`

  return (
    <Helmet
      htmlAttributes={{
        lang: "pl",
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        // {
        //   name: `keywords`,
        //   content: site.keywords,
        // },
        // {
        //   name: `image`,
        //   content: fullURL(imageURL),
        // },
        // ogp stuff
        {
          property: `og:url`,
          content: fullURL(pathname),
        },
        {
          property: `og:title`,
          content: ogTitle || title,
        },
        {
          property: `og:description`,
          content: ogDescription || description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        // twitter stuff
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: twitterTitle || title,
        },
        {
          name: `twitter:description`,
          content: twitterDescription || description,
        },
        {
          property: `twitter:image`,
          content: twitterImage,
        },
      ]}
    />
  )
}
