import React from "react"
import cx from "classnames"
import DynamicComponent from "./DynamicComponent"

const Grid = ({
  columnsCountTablet = 2,
  columnsCountDesktop = 3,
  columns,
  ...other
}) => (
  <div
    {...other}
    className="flex flex-wrap p-4 container mx-auto justify-center items-center"
  >
    {columns.map(blok => (
      <div
        key={blok._uid}
        className={cx("flex-auto w-full p-2 flex justify-center items-center", {
          "sm:max-w-1/1": columnsCountTablet == 1,
          "sm:max-w-1/2": columnsCountTablet == 2,
          "sm:max-w-1/3": columnsCountTablet == 3,
          "md:max-w-1/1": columnsCountDesktop == 1,
          "md:max-w-1/2": columnsCountDesktop == 2,
          "md:max-w-1/3": columnsCountDesktop == 3,
        })}
      >
        <DynamicComponent blok={blok} />
      </div>
    ))}
  </div>
)

export default Grid
