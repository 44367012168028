import Link from "gatsby-link"
import cx from "classnames"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import rewriteSlug from "../utils/rewriteSlug"
import Button from "./Button"

const Nav = ({ settings, sticky }) => {
  const { signInUrl, signUpUrl, email, phone, main_navi, logo, logoBlack } =
    settings.content
  const [collapsed, setCollapsed] = useState(true)

  return (
    <header
      className={cx(
        "w-full",
        {
          "sticky border-b": sticky,
          "relative md:absolute": !sticky,
        },
        "top-0 z-30"
      )}
    >
      <div className={cx("w-full bg-white p-2")}>
        <div className="container mx-auto flex flex-wrap gap-2 justify-around items-center">
          <a href={`tel:${phone}`}>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            {phone}
          </a>
          <a href={`mailto:${email}`}>
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            {email}
          </a>
          <Button title="Zaloguj się" to={signInUrl} />
          <Button title="Zapisz się" to={signUpUrl} />
        </div>
      </div>
      {sticky && <hr className="mx-4" />}
      <nav
        className={cx({
          "bg-white text-black": sticky,
          "md:text-white": !sticky,
        })}
        role="navigation"
      >
        <div className="relative lg:container mx-auto p-4 flex flex-wrap items-center lg:flex-nowrap">
          <div className="mr-4 md:mr-8">
            <Link to="/">
              <img
                src={(sticky ? logoBlack : logo).filename}
                className="h-20"
              />
            </Link>
          </div>
          <div className="ml-auto md:hidden">
            <button
              className="flex items-center px-3 py-2 border rounded"
              type="button"
              onClick={() => {
                setCollapsed(val => !val)
              }}
            >
              <svg
                className="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={
              `${collapsed ? "h-0" : "h-auto"} ` +
              "md:h-auto overflow-hidden w-full md:w-auto md:flex-grow md:flex md:items-center"
            }
          >
            <ul className="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 md:ml-auto lg:mr-8 md:border-0">
              {main_navi.map((navitem, index) => (
                <li key={index}>
                  <Link
                    to={`${
                      navitem.link.cached_url.startsWith("/") ? "" : "/"
                    }${rewriteSlug(navitem.link.cached_url)}`}
                    prefetch="true"
                    className="uppercase font-bold block px-4 py-1 md:p-1 lg:px-6"
                  >
                    {navitem.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Nav
