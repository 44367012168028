import React from "react"
import { Link } from "gatsby"

const Button = ({ title, to, blok, ...other }) => {
  const { tag: Tag, ...linkProps } = getLinkProps(to)

  return (
    <Tag
      {...linkProps}
      {...other}
      className="flex text-center bg-primary rounded-full p-2 px-6 text-lg"
    >
      {title}
    </Tag>
  )
}

function getLinkProps(to) {
  switch (to.linktype) {
    case "story": {
      return {
        tag: Link,
        to: `/${to.cached_url}`,
      }
    }
    default: {
      return {
        tag: "a",
        href: to.cached_url,
        rel: "norefferer noopener",
      }
    }
  }
}

export default Button
