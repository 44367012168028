import React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import Teaser from "./Teaser"
import Feature from "./Feature"
import Grid from "./Grid"
import BlogPost from "./BlogPost"
import Slide from "./Slide"
import Placeholder from "./Placeholder"
import ArticleTeaser from "./ArticleTeaser"
import FeaturedArticles from "./FeaturedArticles"
import PostsList from "./PostsList"
import Button from "./Button"
import FullScreenTeaser from "./FullScreenTeaser"
import Container from "./Container"
import RichText from "./RichText"
import { EmbeddedVideo } from "./EmbeddedVideo"

const Components = {
  teaser: Teaser,
  grid: Grid,
  feature: Feature,
  blogpost: BlogPost,
  slide: Slide,
  "article-teaser": ArticleTeaser,
  "featured-articles": FeaturedArticles,
  "posts-list": PostsList,
  button: Button,
  "full-screen-teaser": FullScreenTeaser,
  container: Container,
  "rich-text": RichText,
  "embedded-video": EmbeddedVideo,
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    const { _uuid, _editable, component, ...actualData } = blok
    return (
      <Component
        {...actualData}
        {...sbEditable(blok)}
        blok={blok}
        key={blok._uid}
      />
    )
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
