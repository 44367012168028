import React from "react"
import cx from "classnames"
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable"
import * as styles from "./RichText.module.scss"
import { EmbeddedVideo } from "./EmbeddedVideo"
import { Table } from "./Table"

const components = {
  "embedded-video": EmbeddedVideo,
  table: Table
}

const NotImplemented = () => <div>Not implemented</div>

const RichText = ({ blok }) => {
  const { text, className } = blok
  return (
    <div
      {...sbEditable(blok)}
      className={cx("w-full", styles.editor, className)}
    >
      {render(text, {
        defaultBlokResolver: NotImplemented,
        blokResolvers: components,
      })}
    </div>
  )
}

export default RichText
