import React from "react"
import Navigation from "./Navigation"
import Footer from "./Footer"
import { useStaticQuery, graphql } from "gatsby"
import { CookieConsent } from "./CookieConsent"

export default function Layout({ children, location, lang }) {
  const { settings: querySettings } = useStaticQuery(graphql`
    query Settings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)

  const isHome = location.pathname === "/"
  let correctSetting = querySettings.edges
  let hasSetting =
    correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content =
    typeof hasSetting.content === "string"
      ? JSON.parse(hasSetting.content)
      : hasSetting.content
  let settings = Object.assign({}, content, { content: content })

  return (
    <div className="flex flex-col min-h-screen">
      <CookieConsent />
      <Navigation settings={settings} sticky={!isHome} />
      <main className="flex-1">{children}</main>
      <Footer settings={settings} />
    </div>
  )
}
