import React from "react"

export const EmbeddedVideo = ({ src, ...other }) => {
  return (
    <div
      style={{
        paddingBottom: "56.25%",
        position: "relative",
      }}
      {...other}
    >
      <iframe
        width="100%"
        height="100%"
        src={src}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        className="absolute top-0 left-0 w-full h-full"
      />
    </div>
  )
}
