import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponent from "./DynamicComponent"

const FullScreenTeaser = ({ blok }) => {
  const { footer, header, subheader, image, filterOpacity = 0 } = blok
  const body = footer?.map(childBlok => (
    <DynamicComponent blok={childBlok} key={childBlok._uid} />
  ))
  return (
    <div
      {...sbEditable(blok)}
      className="relative h-screen bg-black bg-cover bg-center flex flex-col justify-center md:justify-end"
      style={{
        backgroundImage: `url(${image.filename})`,
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          backgroundColor: `rgba(0,0,0,${filterOpacity})`,
        }}
      />
      <div className="text-center pb-15% z-10">
        <div className="p-6 container mx-auto pb-14">
          <h1 className="text-3xl text-white">{header}</h1>
          <div className="text-white text-3xl pt-5">{subheader}</div>
        </div>
        <div className="relative flex w-full p-8 snap-x justify-around max-w-screen-sm m-auto flex-wrap gap-5">
          {body}
        </div>
      </div>
    </div>
  )
}

export default FullScreenTeaser
