import React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import { render } from "storyblok-rich-text-react-renderer"

const Feature = ({ blok }) => {
  const { description, name, icon } = blok
  return (
    <div
      {...sbEditable(blok)}
      className="bg-white py-10 w-full text-center flex flex-col items-center"
    >
      <img className="w-14 h-14" src={icon.filename} />
      <div className="p-2">
        <p className="text-base text-gray-600">{render(description)}</p>
      </div>
    </div>
  )
}

export default Feature
