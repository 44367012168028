import React from "react"
import cx from "classnames"

export const Table = ({ data, className, ...other }) => {
  console.log("data!", data)
  const { thead = [], tbody = [] } = data
  return (
    <table className={cx(className, "table-auto border-collapse border")} {...other}>
      <thead>
        <tr>
          {thead.map(({ value }) => (
            <th className="border border-gray-500 p-1">{value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody.map(({ body }) => (
          <tr>
            {body.map(({ value }) => (
              <td className="border border-gray-500 p-1">{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
