import React from "react"
import { Link } from "gatsby"

const Footer = ({ settings }) => {
  const { footerBanner, footerLinks = [] } = settings
  return (
    <footer className="text-center flex flex-col items-center py-14 px-2 md:px-6 container mx-auto">
      <div className="flex items-center my-8 gap-5">
        {footerLinks.map(({ label, link }, index) => (
          <Link key={index} to={`/${link.cached_url}`}>
            {label}
          </Link>
        ))}
      </div>
      {footerBanner && <img className="max-h-24" src={footerBanner.filename} />}
    </footer>
  )
}

export default Footer
